// import KeenSlider from 'keen-slider'
// import KeenSlider from '../../node_modules/keen-slider/index'
// import KeenSlider from 'keen-slider';

// declare var KeenSlider: any;


class CardsBuilder {
    cards: Array<Card>;
    pathToIcons = 'dist/assets/icons/'

    createCards() {
        this.cards = [
            this.createCard(this.pathToIcons + 'Feature1.svg', ['Easy to connect your network of sensors and devices via MQTT broker']),
            this.createCard(this.pathToIcons + 'Feature2.svg', ['High-performance  controller designed to work both in the  cloud and on edge environment']),
            this.createCard(this.pathToIcons + 'Feature3.svg', ['Manage your networks of sensors through an intuitive admin console']),
            this.createCard(this.pathToIcons + 'Feature4.svg', ['Robust workflow with ruleset and script engine to automate various scenarios of data processing']),
            this.createCard(this.pathToIcons + 'Feature5.svg', ['Secured API to access data from your sensors']),
            this.createCard(this.pathToIcons + 'Feature6.svg', ['Discover and operate your sensors with a plug-and-play approach']),
            this.createCard(this.pathToIcons + 'Feature7.svg', ['Geolocation and time series enabled features']),
            this.createCard(this.pathToIcons + 'Feature8.svg', ['Network and data visualization, charts and dashboards']),
            this.createCard(this.pathToIcons + 'Feature9.svg', ['Create and manage a team of users to work with your IoT project']),
            this.createCard(this.pathToIcons + 'Feature10.svg', ['Webhooks to integrate your solution with any third party applications']),
        ]

        let cards = document.getElementById('cards');
        this.cards.forEach(card => {
            let cardEl = document.createElement('div');
            let wrapper = document.createElement('div');
            cardEl.className = 'card';
            wrapper.className = 'card-wrapper';
            let logo = document.createElement('div');
            logo.className = 'logo';
            let text = document.createElement('div');
            text.className = 'text';

            card.texts.forEach(t => {
                let p = document.createElement('p');
                p.innerText = t;
                text.appendChild(p);
            });

            let img = document.createElement('img');
            img.src = card.img;
            logo.appendChild(img);
            cardEl.appendChild(logo);
            cardEl.appendChild(text);

            wrapper.appendChild(cardEl);
            cards.appendChild(wrapper);
        })
    }

    createCard(img: string, texts: Array<string>): Card {
        return {img, texts}
    }
}


window.onload = () => {

    // let menu = document.getElementById('menu-btn');
    // menu.onclick = () => {
    //     let header = document.querySelector('.header-wrapper');
    //     header.classList.toggle('opened');
    // }
    //
    // let liElements = document.querySelectorAll('.navigation ul li');
    // liElements.forEach(li => {
    //     li.addEventListener('click', () => {
    //         setTimeout(() => {
    //             let header = document.querySelector('.header-wrapper');
    //             header.classList.toggle('opened');
    //         }, 200)
    //     })
    // })
}


interface Card {
    img: string;
    texts: Array<string>;
}



